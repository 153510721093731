import { USER_ACCESS } from "constant"

export const hasAccess = (access) => {
    return access === USER_ACCESS.WRITE || access === USER_ACCESS.READ;
}

export const hasWriteAccess = (access) => {
    return access === USER_ACCESS.WRITE;
}

export const hasReadAccess = (access) => {
    return access === USER_ACCESS.READ;
}

export const PAGE_ACCESS_MAPPING = {
    '/learning': { section: 'Learning', accessKey: 'learningAccess' },
    '/learning/[categoryId]': { section: 'Learning', accessKey: 'learningAccessModules' },
    '/learning/[categoryId]/[courseId]': { section: 'Learning', accessKey: 'learningAccessCourses' },
    '/learning/[categoryId]/[courseId]/[topicId]': { section: 'Learning', accessKey: 'learningAccessTopics' },
    '/learning/[categoryId]/[courseId]/[topicId]/[topicVideoId]': { section: 'Learning', accessKey: 'learningPlayVideos' },

    '/feeds': { section: 'Feed', accessKey: 'feedAccess' },
    '/feeds/[feedId]': { section: 'Feed', accessKey: 'feedAccess' },
    '/feeds/user/[userId]': { section: 'Feed', accessKey: 'feedAccess' },
    '/bookmarks': { section: 'Feed', accessKey: 'feedAccess' },

    '/quizzes': { section: 'Quiz', accessKey: 'quizAccess' },
    '/quizzes/[quizzId]': { section: 'Quiz', accessKey: 'quizAccess' },
    '/quizzes/[quizzId]/attempt-quiz': { section: 'Quiz', accessKey: 'quizAccess' },
    '/quizzes/[quizzId]/quiz-result': { section: 'Quiz', accessKey: 'quizAccess' },

    '/quiz': { section: 'Quiz', accessKey: 'quizAccess' },
    '/quiz/[quiz]': { section: 'Quiz', accessKey: 'quizAccess' },
    '/quiz/[quiz]/attempt-quiz': { section: 'Quiz', accessKey: 'quizAccess' },
    '/quiz/[quiz]/quiz-result': { section: 'Quiz', accessKey: 'quizAccess' },


    '/webinar': { section: 'Webinar', accessKey: 'webinarAccess' },
    '/webinar/[webinarId]': { section: 'Webinar', accessKey: 'webinarAccess' },
    '/webinar/participant/[webinarId]': { section: 'Webinar', accessKey: 'webinarAccess' },

    '/olympiad': { section: 'Olympiad', accessKey: 'olympiadAccess' },
    '/olympiad/top-performers': { section: 'Olympiad', accessKey: 'olympiadAccess' },
    '/olympiad/today-leaderboard': { section: 'Olympiad', accessKey: 'olympiadAccess' },
    '/olympiad/all-time-leaderboard': { section: 'Olympiad', accessKey: 'olympiadAccess' },
    '/olympiad/[olympiadId]': { section: 'Olympiad', accessKey: 'olympiadAccess' },
    '/olympiad/[olympiadId]/olympiad-result': { section: 'Olympiad', accessKey: 'olympiadAccess' },

    '/medicine/[slug]': { section: 'Medicine', accessKey: 'medicinePdpAccess' },
    '/medicines': { section: 'Medicine', accessKey: 'medicinePlpAccess' },
    '/medicines/[slug]': { section: 'Medicine', accessKey: 'medicinePlpAccess' },
    '/medicines/category': { section: 'Medicine', accessKey: 'medicinePlpAccess' },
    '/medicines/category/[id]': { section: 'Medicine', accessKey: 'medicinePlpAccess' },
    '/medicines/allMedicines': { section: 'Medicine', accessKey: 'medicinePlpAccess' },

    '/offers': { section: 'Offers', accessKey: 'offersAccesss' },
    '/offers/[slug]': { section: 'Offers', accessKey: 'offersAccesss' },
    '/offers/[slug]/dealPage': { section: 'Offers', accessKey: 'offersAccesss' },
    '/offers/[slug]/upcomingDealPage': { section: 'Offers', accessKey: 'offersAccesss' },
    
    '/[mediaType]': { section: 'Blogs', accessKey: 'blogsAccess' },
    '/[mediaType]/[blogLanguage]': { section: 'Blogs', accessKey: 'blogsAccess' },
    '/[mediaType]/[blogLanguage]/[blogCategory]': { section: 'Blogs', accessKey: 'blogsAccess' },
    '/[mediaType]/[blogLanguage]/[blogCategory]/[blogTitle]': { section: 'Blogs', accessKey: 'blogsAccess' },
    
    '/ayurvedic-doctor': { section: 'FindDoctors', accessKey: 'findDoctorsAccess' },
    '/ayurvedic-doctor/[city]': { section: 'FindDoctors', accessKey: 'findDoctorsAccess' },
    '/ayurvedic-doctor/[city]/[doctorName]': { section: 'FindDoctors', accessKey: 'findDoctorsAccess' },

    '/ayurveda/doctors': { section: 'FindDoctors', accessKey: 'findDoctorsAccess' },
    '/ayurveda/[city]/doctors': { section: 'FindDoctors', accessKey: 'findDoctorsAccess' },
    '/ayurveda/[city]/doctor/[doctorName]': { section: 'FindDoctors', accessKey: 'findDoctorsAccess' },

    '/clinics': { section: 'FindClinics', accessKey: 'findClinicsAccess' },
    '/clinics/[city]': { section: 'FindClinics', accessKey: 'findClinicsAccess' },
    '/clinics/[city]/[clinicName]': { section: 'FindClinics', accessKey: 'findClinicsAccess' },

    '/ayurveda/clinics': { section: 'FindClinics', accessKey: 'findClinicsAccess' },
    '/ayurveda/[city]/clinics': { section: 'FindClinics', accessKey: 'findClinicsAccess' },
    '/ayurveda/[city]/clinic/[clinicName]': { section: 'FindClinics', accessKey: 'findClinicsAccess' },

    '/appointment-requests': { section: 'D2C', accessKey: 'd2cRequestAppointment' },

    '/treatments/skin-diseases': { section: 'D2C', accessKey: 'd2cTreatmentsAccess' },
    '/treatments/pain-management': { section: 'D2C', accessKey: 'd2cTreatmentsAccess' },
    '/treatments/liver-problems': { section: 'D2C', accessKey: 'd2cTreatmentsAccess' },
    '/treatments/kidney-problems': { section: 'D2C', accessKey: 'd2cTreatmentsAccess' },
    '/treatments/digestive-issues': { section: 'D2C', accessKey: 'd2cTreatmentsAccess' },
    '/treatments/lifestyle-disorders': { section: 'D2C', accessKey: 'd2cTreatmentsAccess' },
    '/treatments/female-health-issues': { section: 'D2C', accessKey: 'd2cTreatmentsAccess' },
    '/treatments/respiratory-problems': { section: 'D2C', accessKey: 'd2cTreatmentsAccess' },
    '/treatments/skin-diseases/[slug]': { section: 'D2C', accessKey: 'd2cTreatmentsAccess' },
    '/treatments/pain-management/[slug]': { section: 'D2C', accessKey: 'd2cTreatmentsAccess' },
    '/treatments/liver-problems/[slug]': { section: 'D2C', accessKey: 'd2cTreatmentsAccess' },
    '/treatments/kidney-problems/[slug]': { section: 'D2C', accessKey: 'd2cTreatmentsAccess' },
    '/treatments/digestive-issues/[slug]': { section: 'D2C', accessKey: 'd2cTreatmentsAccess' },
    '/treatments/lifestyle-disorders/[slug]': { section: 'D2C', accessKey: 'd2cTreatmentsAccess' },
    '/treatments/female-health-issues/[slug]': { section: 'D2C', accessKey: 'd2cTreatmentsAccess' },
    '/treatments/respiratory-problems/[slug]': { section: 'D2C', accessKey: 'd2cTreatmentsAccess' },

    '/services/[slug]': { section: 'D2C', accessKey: 'd2cServicesAccess' },

    '/reseller/about': { section: 'NirogPartner', accessKey: 'nirogPartnerAccessAbout' },
    '/reseller': { section: 'NirogPartner', accessKey: 'nirogPartnerAccessDashboard' },
    '/reseller/selectPatientAddress': { section: 'NirogPartner', accessKey: 'nirogPartnerAccessDashboard' },
    '/reseller/medicines': { section: 'NirogPartner', accessKey: 'nirogPartnerAccessDashboard' },
    '/reseller/cart': { section: 'NirogPartner', accessKey: 'nirogPartnerAccessDashboard' },
    '/reseller/[tokenCode]': { section: 'NirogPartner', accessKey: 'nirogPartnerAccessOrderLink' },
    'reseller/prescription/[orderId]': { section: 'NirogPartner', accessKey: 'nirogPartnerAccessDashboard' },
    '/my-patients': { section: 'NirogPartner', accessKey: 'nirogPartnerAccessMyPatients' },
    '/my-patients/view/[patientId]': { section: 'NirogPartner', accessKey: 'nirogPartnerAccessMyPatients' },
    '/my-patients/select-address/[patientId]': { section: 'NirogPartner', accessKey: 'nirogPartnerAccessMyPatients' },
    '/my-patients/edit/[patientId]': { section: 'NirogPartner', accessKey: 'nirogPartnerAccessMyPatients' },
    '/my-appointments': { section: 'NirogPartner', accessKey: 'nirogPartnerAccessMyAppointments' },
    '/reseller/orders': { section: 'NirogPartner', accessKey: 'nirogPartnerAccessMyOrders' },
    '/appointment-calendar': { section: 'NirogPartner', accessKey: 'nirogPartnerAccessMyAppointments' },
    '/my-leads': { section: 'NirogPartner', accessKey: 'nirogPartnerAccessMyLeads' },
    '/myclinic': { section: 'NirogPartner', accessKey: 'nirogPartnerAccessMyClinic' },
    '/reseller/bankdetails': { section: 'NirogPartner', accessKey: 'nirogPartnerAccessBankDetails' },

    '/health-conditions': { section: 'D2C', accessKey: 'd2cHealthConditionsAccess' },
    '/health-conditions/[slug]': { section: 'D2C', accessKey: 'd2cHealthConditionsAccess' },
}
