
import * as HttpService from "./http.service";
import { getAuth } from "./identity.service";
import {
    DR_REGISTRATION, SEND_OTP_FOR_REG, creat_user_URL, SEND_OTP_FOR_LOGIN, LOGIN_WITH_MOBILE_OTP,
    LOGIN_WITH_EMAIL_PASSWORD, LOGIN_WITH_MOBILE_PASSWORD, LOGIN_WITH_EMAIL_OTP, SEND_EMAIL_OTP,

    STUDENT_REGISTRATION, CHEMIST_REGISTRATION, RETAILER_REGISTRATION, PERSONAL_AND_CONTACTS, PERSONAL_AND_CONTACTS_GET,
    CREATE_EXPERIENCE, CREATE_AWARD, CREATE_MEMBERSHIP, CREATE_EDUCATION, SERVICES_AND_SPECIALIZATIONS,
    CREATE_SERVICES, CREATE_SPECIALIZATION, DELETE_EXPERIENCE, DELETE_AWARD, DELETE_MEMBERSHIP, DELETE_EDUCATION,
    DR_REGISTRATION_NODE, VERIFY_OTP_MOBILE, SEND_OTP_FOR_UPDATE, VERIFY_OTP_EMAIL, USER_REGISTRATION_UPDATE_DELETE,
    CHECK_IF_VERIFIED, CHANGE_PASSWORD_EMAIL, CHANGE_PASSWORD_MOBILE, GET_USER_PROFILE_NODE, GET_OTHER_USER_PROFILE_NODE,
    UPDATE_USER_PERSONAL_CONTACT_INFO, USER_FOLLOWERS_LIST, USER_FOLLOWINGS_LIST, ADD_EDUCATION_NODE, UPDATE_EDUCATION_NODE,
    DELETE_EDUCATION_NODE, ADD_EXPERIENCE_NODE, UPDATE_EXPERIENCE_NODE, DELETE_EXPERIENCE_NODE, GET_SERVICES_SPECILIZATION_LIST,
    ADD_SERVICES_NODE, ADD_SPECILIZATION_NODE, DELETE_SERVICES_NODE, DELETE_SPECIALIZATION_NODE, ADD_MEMBERSHIP_NODE,
    UPDATE_MEMBERSHIP_NODE, DELETE_MEMBERSHIP_NODE, DELETE_AWARD_NODE, UPDATE_AWARD_NODE, ADD_AWARD_NODE, GET_PINCODE_DATA,
    GET_QUALIFICATION_REFERENCES, GET_UNIVERSITY_REFERENCES, GET_COUNCIL_REFERENCES, GET_SERVICEBLE_PINCODE, ADD_INTERESTED_USER,
    CHANGE_PUBLIC_VISIBILITY, ADD_UPDATE_ALT_MOBILE_NO, GET_USER_SPECIALIZATIONS, ADD_SALE_NOTIFICATION, GET_SALE_NOTIFICATION, SAVE_USER_SEARCH, GET_USER_SEARCH, UPDATE_USER_SEARCH_TERM_STATUS, GET_REFFERAL_CONTENT, GET_INTERESTED_OLYMPIAD_USER, VERIFY_OTP_OLYMPIAD_USER, SEND_OTP_OLYMPIAD_USER, GET_USER_ACCESS, CHECK_IF_VERIFIED_REFRESH, SELL_BUSINESS_OTP, SELL_BUSINESS_OTP_WITH_PUB, SELL_BUSINESS_QUERY, SELL_BUSINESS_QUERY_WITH_PUB, GET_DOCTOR_TIMINGS, GET_DOCTOR_APPOINTMENT_SLOTS, GET_USER_TYPES_DATA, USER_INACTIVE, CREATE_INACTIVE_USER, GET_SYSTEM_PARAMETER,
    SEND_VERIFICATION_EMAIL,
    VERIFY_EMAIL,
    GET_DOCTOR_RATINGS,
    CHANGE_CURATED_STATUS_FOR_DOCTOR,
    GET_OUTSTANDING_PAYOUT,
    TRANSFER_OUTSTANDING_PAYOUT,
    GET_LANGUAGES,
    SAVE_PREFERRED_LANGUAGES,
    REMOVE_LANGUAGE,
    GET_LANGUAGE_LIST_WITH_PUB,
    UPDATE_USER_PROFESSION,
    CHANGE_CLINIC_CONSULTATION,
    GET_CONSULTATION_FEES_LIST,
    GET_REWARDS_EARNED
} from "./url.service";


const auth = getAuth();

export const drRegistration = (data, id) => {
    return HttpService.postWithAuth(DR_REGISTRATION({ id }), data);
};

export const drRegistrationNode = (data, id) => {
    return HttpService.postWithAuth(DR_REGISTRATION_NODE({ id }), data);
}

export const sendOtp = (data) => {
    return HttpService.postWithOutAuth(SEND_OTP_FOR_REG(), data);
};

export const sendOtpUpdate = (data) => {
    return HttpService.postWithOutAuth(SEND_OTP_FOR_UPDATE(), data);
}

export const verifyOtpMobile = (data) => {
    return HttpService.postWithOutAuth(VERIFY_OTP_MOBILE(), data);
}

export const verifyOtpEmail = (data) => {
    return HttpService.postWithOutAuth(VERIFY_OTP_EMAIL(), data);
}

export const creatUserApi = (data) => {
    return HttpService.postWithOutAuth(creat_user_URL(), data);
};

export const sendOtpForLogin = (data) => {
    return HttpService.postWithOutAuth(SEND_OTP_FOR_LOGIN(), data);
};

export const LoginWithMobileOtp = (data) => {
    return HttpService.postWithOutAuth(LOGIN_WITH_MOBILE_OTP(), data);
}

export const loginWithEmailAndPassword = (data) => {
    return HttpService.postWithOutAuth(LOGIN_WITH_EMAIL_PASSWORD(), data);
}

export const loginWithMobileAndPassword = (data) => {
    return HttpService.postWithOutAuth(LOGIN_WITH_MOBILE_PASSWORD(), data);
}

export const sendOtpForLoginWithEmail = (data) => {
    return HttpService.postWithOutAuth(SEND_EMAIL_OTP(), data);
}

export const LoginWithEmailOtp = (data) => {
    return HttpService.postWithOutAuth(LOGIN_WITH_EMAIL_OTP(), data);
}

export const addInterestedUser = (data) => {
    return HttpService.postWithOutAuth(ADD_INTERESTED_USER(), data);
}


export const studentRegistration = (data, id) => {
    return HttpService.postWithAuth(STUDENT_REGISTRATION({ id }), data);
};
export const chemistRegistration = (data, id) => {
    return HttpService.postWithAuth(CHEMIST_REGISTRATION({ id }), data);
};

export const retailerRegistration = (data, id) => {
    return HttpService.postWithAuth(RETAILER_REGISTRATION({ id }), data);
};

export const createExperience = (data) => {
    return HttpService.postWithAuth(CREATE_EXPERIENCE(), data);
};

export const deleteExperience = (data) => {
    return HttpService.postWithAuth(DELETE_EXPERIENCE(), data);
}

export const createAward = (data) => {
    return HttpService.postWithAuth(CREATE_AWARD(), data);
};

export const deleteAward = (data) => {
    return HttpService.postWithAuth(DELETE_AWARD(), data);
}

export const createMembership = (data) => {
    return HttpService.postWithAuth(CREATE_MEMBERSHIP(), data);
};

export const deleteMembership = (data) => {
    return HttpService.postWithAuth(DELETE_MEMBERSHIP(), data);
}

export const createEducationData = (data) => {
    return HttpService.postWithAuth(CREATE_EDUCATION(), data);
};

export const deleteEducationData = (data) => {
    return HttpService.postWithAuth(DELETE_EDUCATION(), data);
}

export const personalAndContactInfo = (data) => {
    return HttpService.postWithAuth(PERSONAL_AND_CONTACTS(), data);
};

export const personalAndContactInfoGet = (data) => {
    return HttpService.postWithAuth(PERSONAL_AND_CONTACTS_GET(), data);
};

export const createServices = (data) => {
    return HttpService.postWithAuth(CREATE_SERVICES(), data);
};

export const createSpecializationData = (data) => {
    return HttpService.postWithAuth(CREATE_SPECIALIZATION(), data);
};

export const ServicesAndSpecializations = (data) => {
    return HttpService.postWithAuth(SERVICES_AND_SPECIALIZATIONS(), data);
};

export const updateUserRegistration = (data, userId, id) => {
    return HttpService.putWithAuth(USER_REGISTRATION_UPDATE_DELETE(userId, id), data);
}

export const deleteUserRegistration = (userId, id) => {
    return HttpService.deleteWithAuth(USER_REGISTRATION_UPDATE_DELETE(userId, id));
}

export const checkIfUserVerified = (userId, userType = null, token = null) => {
    return (token && !auth?.token) ? HttpService.getWithToken(CHECK_IF_VERIFIED(userId, userType), token) : HttpService.getWithAuth(CHECK_IF_VERIFIED(userId, auth?.user?.userType));
}

export const checkIfUserVerifiedRefresh = ({ userId, lastUpdatedAt, userType, userRole, token = null }) => {
    return (token && !auth?.token) ? HttpService.getWithToken(CHECK_IF_VERIFIED_REFRESH(userId), token) : HttpService.getWithAuth(CHECK_IF_VERIFIED_REFRESH(userId, lastUpdatedAt, userType, userRole));
}

export const getUserAccessInfo = (userId) => {
    return HttpService.getWithAuth(GET_USER_ACCESS(userId));
}

export const changePasswordEmail = (data) => {
    return HttpService.postWithOutAuth(CHANGE_PASSWORD_EMAIL(), data);
}

export const changePasswordMobile = (data) => {
    return HttpService.postWithOutAuth(CHANGE_PASSWORD_MOBILE(), data);
}

export const getUserFollowersList = (userId, token) => {
    return HttpService.getWithToken(USER_FOLLOWERS_LIST(userId), token)
}

export const getUserFollowingsList = (userId, token) => {
    return HttpService.getWithToken(USER_FOLLOWINGS_LIST(userId), token)
}

export const getUserReferralContent = (userId) => {
    return HttpService.getWithAuth(GET_REFFERAL_CONTENT(userId))
}

// -------------------------------------------------------------------------------------------------------------------------------------
//Node APIs for user profile(Add any other api then to be used in profile section above or below the section)

export const getUserProfile = (userId, accordion) => {
    return HttpService.getWithAuth(GET_USER_PROFILE_NODE(userId, accordion));
}

export const getOtherUserProfile = (userId) => {
    return HttpService.getWithAuth(GET_OTHER_USER_PROFILE_NODE(userId));
}

export const updateUserPersonalContact = (data) => {
    return HttpService.putWithAuth(UPDATE_USER_PERSONAL_CONTACT_INFO(), data);
}

export const AddEducation = (data) => {
    return HttpService.postWithAuth(ADD_EDUCATION_NODE(), data);
}

export const UpdateEducation = (data) => {
    return HttpService.putWithAuth(UPDATE_EDUCATION_NODE(), data);
}

export const DeleteEducation = (data) => {
    return HttpService.deleteWithAuth(DELETE_EDUCATION_NODE(data));
}

export const AddExperience = (data) => {
    return HttpService.postWithAuth(ADD_EXPERIENCE_NODE(), data);
}

export const UpdateExperience = (data) => {
    return HttpService.putWithAuth(UPDATE_EXPERIENCE_NODE(), data);
}

export const DeleteExperience = (data) => {
    return HttpService.deleteWithAuth(DELETE_EXPERIENCE_NODE(data));
}

export const GetServicesSpecilizationList = (type, query = {}) => {
    let queryUrl = ``;
    if (Object.keys(query).length > 0) {
        Object.keys(query).forEach((q, index) => {
            if (query[q]) {
                if (Object.keys(query).length === (index + 1)) {
                    queryUrl += `${q}=${query[q]}`;
                } else {
                    queryUrl += `${q}=${query[q]}&`;
                }
            }
        });
    }
    return HttpService.getWithOutAuth(`${GET_SERVICES_SPECILIZATION_LIST({ type })}${queryUrl.length > 1 ? `?${queryUrl}` : ""}`);
}

export const AddServices = (data) => {
    return HttpService.postWithAuth(ADD_SERVICES_NODE(), data);
}

export const DeleteService = (data) => {
    return HttpService.deleteWithAuth(DELETE_SERVICES_NODE(data));
}

export const AddSpecilization = (data) => {
    return HttpService.postWithAuth(ADD_SPECILIZATION_NODE(), data);
}

export const DeleteSpecialization = (data) => {
    return HttpService.deleteWithAuth(DELETE_SPECIALIZATION_NODE(data));
}
export const AddAward = (data) => {
    return HttpService.postWithAuth(ADD_AWARD_NODE(), data);
}

export const UpdateAward = (data) => {
    return HttpService.putWithAuth(UPDATE_AWARD_NODE(), data);
}

export const DeleteAward = (data) => {
    return HttpService.deleteWithAuth(DELETE_AWARD_NODE(data));
}

export const AddMembership = (data) => {
    return HttpService.postWithAuth(ADD_MEMBERSHIP_NODE(), data);
}

export const UpdateMembership = (data) => {
    return HttpService.putWithAuth(UPDATE_MEMBERSHIP_NODE(), data);
}

export const DeleteMembership = (data) => {
    return HttpService.deleteWithAuth(DELETE_MEMBERSHIP_NODE(data));
}
export const GetPincodeData = (pincode) => {
    return HttpService.getWithOutAuth(GET_PINCODE_DATA({ pincode }));
}

export const GetPincodeDataForProfile = ({ pincode, forProfile = "" }) => {
    return HttpService.getWithOutAuth(GET_PINCODE_DATA({ pincode, forProfile }));
}

export const GetServiceblePincode = (pincode, channel) => {
    return HttpService.getWithOutAuth(GET_SERVICEBLE_PINCODE(pincode, channel));
}

export const GetQualificationReferences = (query = {}) => {
    let queryUrl = ``;
    if (Object.keys(query).length > 0) {
        Object.keys(query).forEach((q, index) => {
            if (query[q]) {
                if (Object.keys(query).length === (index + 1)) {
                    queryUrl += `${q}=${query[q]}`;
                } else {
                    queryUrl += `${q}=${query[q]}&`;
                }
            }
        });
    }
    return HttpService.getWithOutAuth(`${GET_QUALIFICATION_REFERENCES()}${queryUrl.length > 1 ? `?${queryUrl}` : ""}`);
}

export const GetUniversityReferences = (query = {}) => {
    let queryUrl = ``;
    if (Object.keys(query).length > 0) {
        Object.keys(query).forEach((q, index) => {
            if (query[q]) {
                if (Object.keys(query).length === (index + 1)) {
                    queryUrl += `${q}=${query[q]}`;
                } else {
                    queryUrl += `${q}=${query[q]}&`;
                }
            }
        });
    }
    return HttpService.getWithOutAuth(`${GET_UNIVERSITY_REFERENCES()}${queryUrl.length > 1 ? `?${queryUrl}` : ""}`);
}

export const GetCouncilReferences = (query = {}) => {
    let queryUrl = ``;
    if (Object.keys(query).length > 0) {
        Object.keys(query).forEach((q, index) => {
            if (query[q]) {
                if (Object.keys(query).length === (index + 1)) {
                    queryUrl += `${q}=${query[q]}`;
                } else {
                    queryUrl += `${q}=${query[q]}&`;
                }
            }
        });
    }
    return HttpService.getWithOutAuth(`${GET_COUNCIL_REFERENCES()}${queryUrl.length > 1 ? `?${queryUrl}` : ""}`);
}

export const ChangePublicVisibility = (data) => {
    return HttpService.putWithAuth(CHANGE_PUBLIC_VISIBILITY(), data);
}
export const addUpdateAltMobileNoApi = (id, data) => {
    return HttpService.putWithAuth(ADD_UPDATE_ALT_MOBILE_NO(id), data);
}

export const getUserSpecializations = (userId) => {
    return HttpService.getWithAuth(GET_USER_SPECIALIZATIONS(userId));
};

export const addSaleNotification = (body) => {
    return HttpService.postWithAuth(ADD_SALE_NOTIFICATION(), body);
}

export const getSaleNotification = (mobile, saleLiveDay) => {
    return HttpService.getWithAuth(GET_SALE_NOTIFICATION(mobile, saleLiveDay));
}

export const saveUserSearch = (data, userId) => {
    return HttpService.postWithAuth(SAVE_USER_SEARCH(userId), data);
}

export const getUserSearch = (userId) => {
    return HttpService.getWithAuth(GET_USER_SEARCH(userId));
}

export const updateUserSearchTermStatus = (userId, itemIdsToUpdate) => {
    return HttpService.putWithAuth(UPDATE_USER_SEARCH_TERM_STATUS(userId), { itemIdsToUpdate });
}
export const getInterestedOlympiadUser = (data) => {
    return HttpService.postWithOutAuth(GET_INTERESTED_OLYMPIAD_USER(), data);
}
export const verifyOtpOlympiadUser = (data) => {
    return HttpService.postWithOutAuth(VERIFY_OTP_OLYMPIAD_USER(), data);
}
export const nonLoginOlympiadUser = (data) => {
    return HttpService.postWithOutAuth(SEND_OTP_OLYMPIAD_USER(), data);
}
export const sellBusinessOtp = (data) => {
    if (auth) {
        return HttpService.postWithAuth(SELL_BUSINESS_OTP(), data);
    }
    return HttpService.postWithOutAuth(SELL_BUSINESS_OTP_WITH_PUB(), data);
}
export const sellBusinessQuery = (data) => {
    if (auth) {
        return HttpService.postWithAuth(SELL_BUSINESS_QUERY(), data);
    }
    return HttpService.postWithOutAuth(SELL_BUSINESS_QUERY_WITH_PUB(), data);

}

export const getUserTypesData = () => {
    return HttpService.getWithOutAuth(GET_USER_TYPES_DATA());
}

export const getDoctorProfileTimings = async ({ doctorId, clinicId = "", type = "" }) => {
    return HttpService.getWithOutAuth(GET_DOCTOR_TIMINGS({ doctorId, clinicId, type }));
};

export const getDoctorAppointmentSlots = async ({ doctorId, clinicId = "", type = 0 }) => {
    return HttpService.getWithOutAuth(GET_DOCTOR_APPOINTMENT_SLOTS({ doctorId, clinicId, type }));
};

export const getDoctorFee = ({ type }) => {
    return HttpService.getWithOutAuth(GET_CONSULTATION_FEES_LIST({ type }));
}
export const createInactivateUser = (user_id) => {
    return HttpService.getWithAuth(CREATE_INACTIVE_USER(user_id));
}

export const inactivateUser = (userId, data) => {
    return HttpService.putWithAuth(USER_INACTIVE(userId), data);
}

export const sendVerificationEmail = (data) => {
    return HttpService.postWithAuth(SEND_VERIFICATION_EMAIL(), data);
}

export const verifyEmail = (token) => {
    return HttpService.getWithAuth(VERIFY_EMAIL(token));
}

export const getDoctorRating = async ({ doctorId, limit, page, isScale, isPublished, sort }) => {
    return HttpService.getWithOutAuth(GET_DOCTOR_RATINGS({ doctorId, limit, page, isScale, isPublished, sort }));
}

export const changeCuratedStatus = (data) => {
    return HttpService.postWithAuth(CHANGE_CURATED_STATUS_FOR_DOCTOR(), data);
};

export const getOutstandingPayout = (channel) => {
    return HttpService.getWithAuth(GET_OUTSTANDING_PAYOUT(channel));
}

export const transferOutstandingPayout = (data) => {
    return HttpService.postWithAuth(TRANSFER_OUTSTANDING_PAYOUT(), data)
}

export const getLanguages = (searchKey) => {
    return HttpService.getWithAuth(GET_LANGUAGES(searchKey));
}
export const getLanguageListPublic = ({searchKey}) => {
    return HttpService.getWithOutAuth(GET_LANGUAGE_LIST_WITH_PUB(searchKey));
}

export const savePreferredLanguages = (userId, body) => {
    return HttpService.postWithAuth(SAVE_PREFERRED_LANGUAGES(userId), body);
}

export const removeLanguage = (userId, languageId, data) => {
    return HttpService.putWithAuth(REMOVE_LANGUAGE(userId, languageId), data);
}

export const updateUserProfession = (userId, data) => {
    return HttpService.putWithAuth(UPDATE_USER_PROFESSION(userId), data);
}
export const getRewardsEarned = (doctorId) => {
    return HttpService.getWithAuth(GET_REWARDS_EARNED(doctorId))
};

export const getSystemParamData = (paramName, type) => {
    return HttpService.getWithAuth(GET_SYSTEM_PARAMETER(paramName, type));
};

export const changeClinicConsultation = (data) => {
    return HttpService.putWithAuth(CHANGE_CLINIC_CONSULTATION(), data);
};
